<div class="container-fluid">
  <div class="row">
    <div class="col">
      <p class="mt-3"><b class="header-font">Leagues</b></p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p class="mb-0 mt-2">
        <small class="small-info">Leagues to be shown here.</small>
      </p>
      <p></p>
    </div>
  </div>
  <div class="row">
    <div class="col-7">
      <!-- <div class="btn-group me-3">
        <select class="form-select font-12" aria-label="Default select example">
          <option class="text" selected>All Time</option>
          <option value="1">One</option>
        </select>
      </div>
      <div class="btn-group">
        <button class="form-select btn btn-sm pr-35 font-12" (click)="openFilter()">
          Search & Filter
        </button>
        <ul class="dropdown-menu show w-450 p-3" *ngIf="isFilterOpen">
          <div class="row text-end">
            <div class="col-12">
              <button type="button" class="btn btn-sm" (click)="clearFilter()">Clear All</button>
              <button type="button" class="btn btn-secondary btn-sm ms-2" (click)="applyFilter()">Apply</button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="" class="pb-2">Search</label>
              <div class="input-group mb-3">
                <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">Dropdown</button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                </ul>
                <input type="text" class="form-control" aria-label="Text input with dropdown button">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="">Filter</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                  Default checkbox
                </label>
              </div>
            </div>
          </div>
        </ul>
      </div> -->
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <!-- <div class="row mt-3">
        <div class="col-6">
          <div class="row">
            <div class="col-2 text-center">
              <div class="dropdown pagination-dropdown">
                <select class="form-select font-12" aria-label="All Time">
                  <option class="text" selected>Show 10</option>
                  <option value="1">Show 20</option>
                </select>
              </div>
            </div>
            <div class="col-2 text-start ps-0">
              <span class="per-page align-middle"> per page</span>
            </div>
          </div>
        </div>
        <div class="col-6 text-end">
          <p class="pagination-number">1 - 2 of &nbsp;2
            <img role="button" class="h-20" src="../../../assets/left-arrow.png" alt="">
            <img role="button" class="h-20" src="../../../assets/right-arrow.png" alt="">
          </p>
        </div>
      </div> -->
      <div class="row">
        <div class="col-12">
          <table class="custom-table" id="table-gap">
            <thead class="custom-thead">
              <tr class="custom-thead-tr">
                <th scope="col" class="ps-2">
                  League <span class="fs-10">Logo</span>
                </th>
                <th scope="col" class="ps-2">
                  League <span class="fs-10">Name</span>
                </th>
                <th scope="col" class="ps-2">
                  Code <span class="fs-10">Name</span>
                </th>
                <th scope="col" class="ps-2">Country</th>
              </tr>
            </thead>
            <tbody *ngIf="isLeagueList">
              <div [@filterAnimation]="animatedListTotalLength">
                <ng-container *ngIf="isPrizeZoneLoaded">
                  <tr
                    class="custom-tbody-tr cursor-pointer"
                    *ngFor="let league of leaguesList"
                    (click)="leagueDetails()"
                  >
                    <td class="ps-2">
                      <img
                        class="league-logo"
                        src="{{ league.imageUrl }}"
                        alt=""
                      />
                    </td>
                    <td class="ps-2">{{ league?.name }}</td>
                    <td class="ps-2">{{ league?.code_name }}</td>
                    <td class="ps-2">{{ league?.country }}</td>
                  </tr>
                </ng-container>
              </div>
              <ng-container *ngIf="!isPrizeZoneLoaded">
                <tr class="custom-tbody-tr">
                  <td class="ps-2 p-2">
                    <ngx-skeleton-loader
                      [theme]="{ 'background-color': '#fcece8' }"
                      count="2"
                      appearance="line"
                      animation="pulse"
                    ></ngx-skeleton-loader>
                  </td>
                  <td class="ps-2">
                    <ngx-skeleton-loader
                      [theme]="{ 'background-color': '#fcece8' }"
                      count="2"
                      appearance="line"
                      animation="pulse"
                    ></ngx-skeleton-loader>
                  </td>
                  <td class="ps-2">
                    <ngx-skeleton-loader
                      [theme]="{ 'background-color': '#fcece8' }"
                      count="2"
                      appearance="line"
                      animation="pulse"
                    ></ngx-skeleton-loader>
                  </td>
                  <td class="ps-2">
                    <ngx-skeleton-loader
                      [theme]="{ 'background-color': '#fcece8' }"
                      count="2"
                      appearance="line"
                      animation="pulse"
                    ></ngx-skeleton-loader>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <ngx-skeleton-loader
            *ngIf="!isLeagueList"
            count="2"
            appearance="line"
            animation="pulse"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>
