import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { menuData as globalMenuData } from './menu.data';
import { AuthService } from 'src/app/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { filter, takeUntil, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subject, of, Observable } from 'rxjs';
declare var bootstrap: any;

type IGlobalMenu = typeof globalMenuData;

@Component({
  selector: 'rft-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  $destroy = new Subject();
  private openId = environment.openId;
  isLoading = true;

  isFullSidebar: boolean = true;
  permission: any;
  envText: 'Dev' | 'Prod' | '' = '';

  currentPath?: string;
  mainMenuLabel: any;
  menuData: Observable<IGlobalMenu>;
  subMenus: Observable<IGlobalMenu[0]['submenu']>;
  allMenuPermission = new BehaviorSubject<string[]>([]);

  constructor(
    private http: HttpClient,
    public router: Router,
    private _authService: AuthService
  ) {
    // Left most sidebar routes filtering
    this.menuData = this.allMenuPermission.pipe(
      mergeMap((p) => {
        const data = globalMenuData.filter((r) =>
          this.permissionChecker(p, r.mainMenuPermission)
        );
        return of(data);
      })
    );

    // Submenu routes fileting
    this.subMenus = combineLatest([
      this.router.events.pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd)
      ),
      this.allMenuPermission,
      this.menuData,
    ]).pipe(
      takeUntil(this.$destroy),
      // Skipping from here if navigating inside child routes
      filter(([r]) => !r.url.match(`/${this.currentPath}/?`)),
      // If not inside child routes
      mergeMap(([r, p, m]) => {
        // Finding active route
        const activeMenu = m.find((x) =>
          r.urlAfterRedirects.match(`/${x.path}/?`)
        );

        // Setting sub menu label
        this.mainMenuLabel = activeMenu?.mainMenuLabel;

        // Filtering submenus based on subMenuPermission
        const filteredSubMenus =
          activeMenu?.submenu?.filter((r) =>
            this.permissionChecker(p, r.subMenuPermission)
          ) ?? [];

        // current path flag
        this.currentPath = activeMenu?.path;
        console.log(filteredSubMenus)
        return of(filteredSubMenus);
      })
    );
  }

  ngOnInit(): void {
    this.burgerMenu();
    this.callPermissionApi();
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
    // this.selectMenu('inactive');
    this.envText = this.env; // will determine the env
  }

  burgerMenu() {
    this.isFullSidebar = !this.isFullSidebar;
    let that = this;
    // this.toggleBurger();
    //newly added now
    if (this.isFullSidebar) {
      const elem = document.getElementById('sidebar-max');
      // const mainMenuLabelId = document.getElementById("mainMenuLabel");
      elem?.addEventListener('click', function (event: any) {
        console.log('clicked on sidebar max');

        if (elem?.classList.contains('main-menu-max-width')) {
          if (
            ['burger-menu-closed', 'submenu-section', 'content-body'].includes(
              event.target.id
            )
          ) {
            elem?.classList.remove('main-menu-max-width');
            elem?.classList.add('main-menubar');
            elem?.removeEventListener('click', function () {
              return false;
            });
          }
        }
      });

      const content = document.getElementById('content-body');
      content?.addEventListener('click', function (event: any) {
        console.log('clicked on content-body');
        const elem = document.getElementById('sidebar-max');
        if (elem?.classList.contains('main-menu-max-width')) {
          elem?.classList.remove('main-menu-max-width');
          elem?.classList.add('main-menubar');
          that.isFullSidebar = !that.isFullSidebar;
        }
      });
    }
  }

  public callPermissionApi() {
    const token = localStorage.getItem('accessToken');
    let permissionApi = `${this.openId.accountsUrl}/v1/permission/mine`;
    var header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
    };
    this.http.get(permissionApi, header).subscribe(
      (response: any) => {
        localStorage.setItem(
          'permission',
          JSON.stringify(response['data'].items)
        );
        this.hasPermission();
      },
      (err) => console.log(err),
      () => (this.isLoading = false)
    );
  }

  hasPermission() {
    // this.showSubMenuWhenReloaded();
    let y = JSON.parse(JSON.stringify(localStorage.getItem('permission')));
    this.permission = JSON.parse(y);
    let menuPermission: string[] = [];

    if (this.permission == null) {
      let y = JSON.parse(JSON.stringify(localStorage.getItem('permission')));
      this.permission = JSON.parse(y);
    } else {
      this.permission.map((x: any) => {
        let len = x.split('.').length;
        for (let i = 1; i <= len + 1; i++) {
          let el = x.split('.', i).join('.');
          menuPermission.push(el);
        }
      });
      this.allMenuPermission.next([...new Set(menuPermission)]);
    }
  }

  public permissionChecker(ps: string[] = [], p: String) {
    return ps.findIndex((s: any) => s == p) != -1;
  }

  public logOut() {
    return this._authService.loggedOut();
  }

  public switchToProd() {
    if (!this.envText) return;

    this.envText = this.envText === 'Prod' ? 'Dev' : 'Prod';
    window.location.href =
      this.envText === 'Prod'
        ? `https://devadmin.realfantasyteams.com`
        : 'https://admin.realfantasyteams.com';
  }

  public get env() {
    const PROD_URL = 'https://devadminapi.realfantasyteams.com/adminService';
    const DEV_URL = 'https://adminapi.realfantasyteams.com/adminService';
    return environment.hostUrl === PROD_URL
      ? 'Prod'
      : environment.hostUrl === DEV_URL
      ? 'Dev'
      : '';
  }

  ngOnDestroy(): void {
    this.$destroy.next(false);
    this.$destroy.unsubscribe();
  }
}
