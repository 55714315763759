import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'rft-copy-btn',
  template: `
    <button class="btn p-0" aria-label="Copy" rftCopyToClipboard [text]="text">
      <img src="../../../assets/copy-red-light.png" width="15" height="15" />
    </button>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyBtnComponent implements OnInit {
  @Input('text') text!: string;

  constructor() {}

  ngOnInit(): void {}
}
