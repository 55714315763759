import { Injectable } from '@angular/core';
export const darkTheme = {
  // 'primary-color': '#455363',
  // 'background-color': '#1f2935',
  // 'text-color': '#fff'
  'background-color': '#282828',
  'side-bar-color': '#282828',
  'top-bar-color': '#282828',
  'proper-card-color': '#3d3d3d',
  'mat-card-color': '#544f4f',
  'primary-text-color': '#FFFFFF',
  'secondary-text-color': '#AAAAAA',
  'inside-card-box-color': '#3d3d3d',
  'match-card-color': '#242526',
  'table-background-color': '#424242'
};

export const lightTheme = {
  'background-color': '#FFFFFF',
  'side-bar-color': '#282828',
  'top-bar-color': '#DD3D1D',
  'proper-card-color': '#F1EFEF',
  'mat-card-color': '#FFFFFF',
  'primary-text-color': '#2D2D2D',
  'secondary-text-color': '#AAAAAA',
  'inside-card-box-color': '#cfdeea',
  'match-card-color': '#FFFFFF',
  'table-background-color': '#FFFFFF'
};

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  toggleDark() {
    this.setTheme(darkTheme);
  }

  toggleLight() {
    this.setTheme(lightTheme);
  }

  private setTheme(theme: any) {
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }
  constructor() { }
}
