import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthService } from './auth.service';

import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import {MatGridListModule } from '@angular/material/grid-list';
import {MatInputModule } from '@angular/material/input';
import {MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { RedirectComponent } from './redirect/redirect.component';
const routes:Routes=[
  {
    path:'redirect',component:RedirectComponent
  },
]
@NgModule({
  declarations: [
    RedirectComponent
  ],
  imports: [CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatGridListModule,
    MatInputModule,
    MatButtonModule,
    HttpClientModule,
    RouterModule.forChild(routes)
  ],
  providers: [AuthService]
})
export class AuthModule { }
