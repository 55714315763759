<div class="container-fluid pe-0 ps-0">
  <div class="row">
    <div class="col-12">
      <p class="mb-0 mt-2">
        <small class="small-info"
          >Prize zones are one of the core settings of RFT. Here, this is the
          place for all the Prize zones.</small
        >
      </p>

      <p></p>
    </div>
  </div>
  <div class="row">
    <div class="col-7"></div>
    <div class="col-5 text-end">
      <button
        #openPrizezoneModal
        class="btn btn-danger md-2 btn-sm font-12"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      >
        Add Prize Zone
      </button>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <table class="custom-table" id="table-gap">
            <thead class="custom-thead">
              <tr class="custom-thead-tr">
                <th scope="col" class="ps-2">Name</th>
                <th scope="col" class="ps-2">
                  Payment <span class="fs-10">Gateway</span>
                </th>
                <th scope="col" class="ps-2">
                  Payout <span class="fs-10">Gateways</span>
                </th>
                <th scope="col" class="ps-2">
                  Effective <span class="fs-10">Currency</span>
                </th>
                <th scope="col" class="ps-2">Status</th>
              </tr>
            </thead>
            <tbody>
              <div [@filterAnimation]="animatedListTotalLength">
                <ng-container *ngIf="isPrizeZoneLoaded">
                  <tr
                    class="custom-tbody-tr"
                    *ngFor="let prizezone of prizezones"
                  >
                    <td class="ps-2 p-2">{{ prizezone?.name }}</td>

                    <!-- <td
                      class="ps-2"
                      *ngFor="let payments of prizezone?.payment"
                    >
                      {{ payments | slice : 0 : 6 }}
                    </td>
                    <td class="ps-2" *ngFor="let payouts of prizezone?.payout">
                      {{ payouts | slice : 0 : 6 }}
                    </td> -->

                    <td class="ps-2">
                      <div *ngFor="let p of prizezone?.payment">
                        {{ p  }}
                        <rft-copy-btn [text]="p"></rft-copy-btn>
                      </div>
                    </td>
                    <td class="ps-2">
                      <div *ngFor="let payouts of prizezone?.payout">
                        {{ payouts }}
                        <rft-copy-btn [text]="payouts"></rft-copy-btn>
                      </div>
                    </td>
                    <td class="ps-2">{{ prizezone?.effectiveCurrency }}</td>
                    <td
                      class="ps-2 text-success"
                      role="button"
                      (click)="getPrizezoneById(prizezone._id)"
                    >
                      View
                    </td>
                  </tr>
                </ng-container>
              </div>
              <ng-container *ngIf="!isPrizeZoneLoaded">
                <tr class="custom-tbody-tr">
                  <td class="ps-2 p-2">
                    <ngx-skeleton-loader
                      [theme]="{ 'background-color': '#fcece8' }"
                      count="2"
                      appearance="line"
                      animation="pulse"
                    ></ngx-skeleton-loader>
                  </td>
                  <td class="ps-2">
                    <ngx-skeleton-loader
                      [theme]="{ 'background-color': '#fcece8' }"
                      count="2"
                      appearance="line"
                      animation="pulse"
                    ></ngx-skeleton-loader>
                  </td>
                  <td class="ps-2">
                    <ngx-skeleton-loader
                      [theme]="{ 'background-color': '#fcece8' }"
                      count="2"
                      appearance="line"
                      animation="pulse"
                    ></ngx-skeleton-loader>
                  </td>
                  <td class="ps-2">
                    <ngx-skeleton-loader
                      [theme]="{ 'background-color': '#fcece8' }"
                      count="2"
                      appearance="line"
                      animation="pulse"
                    ></ngx-skeleton-loader>
                  </td>
                  <td class="ps-2">
                    <ngx-skeleton-loader
                      [theme]="{ 'background-color': '#fcece8' }"
                      count="2"
                      appearance="line"
                      animation="pulse"
                    ></ngx-skeleton-loader>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div class="text-center" *ngIf="isNoDataFound">
            <img
              src="../../../assets/open-box.png"
              class="h-20 mt-5 mb-2"
              alt=""
            />
            <p class="mb-2 fs-14">No prize zones found</p>
            <p class="mb-2">
              <small>Start creating prize zone to see prize zones here.</small>
            </p>
            <button
              #openPrizezoneModal
              class="ui negative basic button md-2 btn-sm font-12"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              Add Prize Zone
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Add Prize Zone-->
<!-- Modal -->
<div
  class="modal fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog mb-0 mt-0 me-0">
    <div class="modal-content" style="height: 100vh">
      <div class="modal-header">
        <h6 class="modal-title fw-bold" id="staticBackdropLabel">
          <label class="fs-16" *ngIf="!isPrizezoneView">Add Prize Zone</label>
          <label class="fs-16" *ngIf="isPrizezoneView"
            >View Prize Zone
            <div
              class="spinner-grow spinner-grow-sm"
              role="status"
              *ngIf="!isGetPrizeZoneData"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </label>
        </h6>
        <button
          type="button"
          #closePrizezoneModal
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closePrizeZoneModal()"
        ></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="prizeZoneForm">
          <div class="row">
            <div class="col-12">
              <label for="name" class="form-label font-12"
                >Prize-zone Name</label
              >
              <input
                type="text"
                *ngIf="!isPrizezoneView"
                required
                formControlName="name"
                class="form-control"
                id="name"
              />
              <p class="mb-0">
                <ngx-skeleton-loader
                  *ngIf="!isGetPrizezoneByIdLoaded && isPrizezoneView"
                  [theme]="{ 'background-color': '#fcece8' }"
                  count="1"
                  appearance="line"
                  animation="pulse"
                ></ngx-skeleton-loader>
              </p>
              <p for="" class="mb-0" *ngIf="isPrizezoneView">
                {{ prizeZoneName }}
              </p>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <label for="countries" class="form-label font-12"
                >Countries</label
              >
              <ng-multiselect-dropdown
                *ngIf="!isPrizezoneView"
                required
                formControlName="countries"
                id="countries"
                [settings]="dropdownSettings"
                [data]="countriesDropdownList"
              >
              </ng-multiselect-dropdown>
              <p class="mb-0">
                <ngx-skeleton-loader
                  *ngIf="!isGetPrizezoneByIdLoaded && isPrizezoneView"
                  [theme]="{ 'background-color': '#fcece8' }"
                  count="1"
                  appearance="line"
                  animation="pulse"
                ></ngx-skeleton-loader>
              </p>
              <div *ngIf="isPrizezoneView">
                <label *ngFor="let countries of viewCountriesDropdownList"
                  >{{ countries?.name }}, &nbsp;</label
                >
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col">
              <label for="prizeZone" class="form-label text-secendary font-12"
                >Select Effective Currency</label
              >
              <select
                *ngIf="!isPrizezoneView"
                class="form-select font-12 red-arrow-dropdown"
                required
                aria-label="All Time"
                formControlName="effectiveCurrency"
              >
                <option
                  class="text"
                  *ngFor="let effectiveCurrency of effectiveCurrencyList"
                  [value]="effectiveCurrency.currency"
                >
                  {{ effectiveCurrency?.currency }}
                </option>
              </select>
              <p class="mb-0">
                <ngx-skeleton-loader
                  *ngIf="!isGetPrizezoneByIdLoaded && isPrizezoneView"
                  [theme]="{ 'background-color': '#fcece8' }"
                  count="1"
                  appearance="line"
                  animation="pulse"
                ></ngx-skeleton-loader>
              </p>
              <p for="" *ngIf="isPrizezoneView">
                {{ selectedEffectiveCurrency }}
              </p>
            </div>
            <div class="col">
              <label for="prizeZone" class="form-label text-secendary font-12"
                >Select Payment Gateway</label
              >
              <select
                *ngIf="!isPrizezoneView"
                class="form-select font-12 red-arrow-dropdown"
                required
                aria-label="All Time"
                formControlName="payment"
              >
                <option
                  class="text"
                  *ngFor="let paymentGateWays of paymentGateWaysList"
                  [value]="paymentGateWays?._id"
                >
                  {{ paymentGateWays?.gatewayName }}
                </option>
              </select>
              <p class="mb-0">
                <ngx-skeleton-loader
                  *ngIf="!isGetPrizezoneByIdLoaded && isPrizezoneView"
                  [theme]="{ 'background-color': '#fcece8' }"
                  count="1"
                  appearance="line"
                  animation="pulse"
                ></ngx-skeleton-loader>
              </p>
              <p for="" *ngIf="isPrizezoneView">{{ selectedPaymentGateway }}</p>
            </div>
            <div class="col">
              <label for="prizeZone" class="form-label text-secendary font-12"
                >Select Payout Gateway</label
              >
              <select
                *ngIf="!isPrizezoneView"
                class="form-select font-12 red-arrow-dropdown"
                required
                aria-label="All Time"
                formControlName="payout"
              >
                <option
                  class="text"
                  *ngFor="let payouts of payoutGateWaysList"
                  [value]="payouts?._id"
                >
                  {{ payouts?.gatewayName }}
                </option>
              </select>
              <p class="mb-0">
                <ngx-skeleton-loader
                  *ngIf="!isGetPrizezoneByIdLoaded && isPrizezoneView"
                  [theme]="{ 'background-color': '#fcece8' }"
                  count="1"
                  appearance="line"
                  animation="pulse"
                ></ngx-skeleton-loader>
              </p>
              <p for="" *ngIf="isPrizezoneView">{{ selectedPayoutGateway }}</p>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-sm btn-secondary"
          data-bs-dismiss="modal"
          (click)="closePrizeZoneModal()"
        >
          Close
        </button>
        <button
          type="button"
          *ngIf="!isPrizezoneView"
          class="btn btn-sm btn-danger"
          (click)="createEditPrizeZone()"
        >
          <!-- [disabled]="this.prizeZoneForm.invalid || addPrizeZoneBtnClicked" -->
          <div
            class="spinner-grow spinner-grow-sm"
            role="status"
            *ngIf="addPrizeZoneBtnClicked"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          Save
        </button>
      </div>
    </div>
  </div>
</div>
