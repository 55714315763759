import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../settings.service';

@Component({
  selector: 'rft-payment-gateways',
  templateUrl: './payment-gateways.component.html',
  styleUrls: ['./payment-gateways.component.scss']
})
export class PaymentGatewaysComponent implements OnInit {
  isFilterOpen: boolean = false;
  isPaymentList: boolean = false;
  isPaymentGatewaysLoaded: boolean = false;
  paymentGatewaysList: any = [];
  constructor(private _settingsService: SettingsService,private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getPaymentGateways();
  }
  public getPaymentGateways() {
    this.isPaymentGatewaysLoaded = false;
    this._settingsService.getLeagueList().subscribe(res => {
      console.log("Payment Gateways =>",res.data.items);
      this.paymentGatewaysList = res.data.items;
      this.isPaymentList = true;
      this.isPaymentGatewaysLoaded = true;
    }, error => {
      this.toastr.error(error.message, 'RFT NOTIFICATION');
    })
  }
  public paymentGatewayDetails(){
    this.router.navigate(['/settings/league-details'])
  }
  openFilter() {
    this.isFilterOpen = true;
  }
  clearFilter() {
    this.isFilterOpen = false;
  }
  applyFilter() {
    this.isFilterOpen = false;
  }
}
