import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DragulaModule } from 'ng2-dragula';
import { RequestInterceptor } from './core/interceptor/request.interceptor';
import { ResponseInterceptor } from './core/interceptor/response.interceptor';
import { AuthGuard } from './core/guards/auth.guard';
import { UserGuard } from './core/guards/user.guard';

import { DashboardModule } from './dashboard/dashboard.module';
import { AuthModule } from './auth/auth.module';
import { SettingsModule } from './settings/settings.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FAYE_CLIENT } from './core/tokens/fayeClient.token';
import fayeClient from './core/classes/faye.client';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      timeOut: 10000,
      // disableTimeOut: true,
      preventDuplicates: true,
      positionClass: 'toast-top-center',
    }),

    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatListModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    HttpClientModule,
    AuthModule,
    DashboardModule,
    SettingsModule,
    DragulaModule.forRoot(),
    BrowserAnimationsModule,
    NgbModule,
  ],
  providers: [
    AuthGuard,
    UserGuard,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: FAYE_CLIENT, useValue: fayeClient },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  click: number = 0;
  token: any;
}
