import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/classes/unsubscriber';

@Component({
  selector: 'rft-core-dashboard',
  templateUrl: './core-dashboard.component.html',
  styleUrls: ['./core-dashboard.component.scss'],
})
export class CoreDashboardComponent extends Unsubscriber implements OnInit {
  tabs = ['Prize zone', 'Leagues']; // Tabs
  $activeTab?: Observable<{ active: string }>;

  constructor(private activatedRoute: ActivatedRoute) {
    super();

    this.$activeTab = this.activatedRoute.queryParams.pipe(
      takeUntil(this.$destroyRef),
      map(({ tab }) => ({ active: !tab ? this.tabs[0] : tab }))
    );
  }

  ngOnInit(): void {}
}
