import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DffService {
  params: any;
  private baseUrl = environment.openId;
  constructor(private http: HttpClient) { }
  public getLeagueList(): Observable<any> {

    return this.http.get(`${this.baseUrl.backendUrl}/v1/adminV2/league/list`).pipe(
      catchError((err: HttpErrorResponse) => {

        return throwError(err);
      }),
    );
  }

  public editSeason(id: any, seasonForm: any): Observable<any> {
    let unassignFilterUrl = this.baseUrl.backendUrl + `/v1/admin/dffSeason/${id}`;
    return this.http.put<any>(`${unassignFilterUrl}`, seasonForm)
      .pipe(
        retry(1),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      )
  }

  public getPrizeZoneList(isActive: boolean): Observable<any> {

    return this.http.get(`${this.baseUrl.backendUrl}/v1/adminV2/prizeZone/list?status=true&byContest=${isActive}`).pipe(
      catchError((err: HttpErrorResponse) => {

        return throwError(err);
      }),
    );
  }

  public createSeason(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl.backendUrl}/v1/admin/dffSeason`, payload)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return;
          }
        },

          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          }),
        )
      )
  }

  public getSeasonList(): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/dffSeason/list`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }

  public getSeasonById(id: any): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/dffSeason/${id}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }

  public createContest(contestForm: any): Observable<any> {
    return this.http.post<any>(this.baseUrl.backendUrl + '/v1/admin/contest', contestForm)
      .pipe(
        retry(1),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      )
  }

  public getContestList(): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/contest/list`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public getContestById(id: any): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/contest?contestId=${id}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public editContest(id: any, contestForm: any): Observable<any> {
    let unassignFilterUrl = this.baseUrl.backendUrl + `/v1/admin/contest/${id}`;
    return this.http.put<any>(`${unassignFilterUrl}`, contestForm)
      .pipe(
        retry(1),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      )
  }

  public getMatchesList(matchType: any,seasonId: any,pageNo: any,limit: any,leagues: any ,teams: any = [],dateFrom: any,dateTo: any,sortingBy: any, sortingType: any): Observable<any> {
    this.params = new HttpParams();
    if(leagues?.length<1){
      this.params = this.params.append('leagues', leagues.join(', '));
    }
    if(teams?.length<1){
      this.params = this.params.append('teamName', teams.join(', '));
    }
    if(leagues?.length>0){
      this.params = this.params.append('leagues', leagues.join(', '));
    }
    if(teams?.length>0){
      this.params = this.params.append('teamName', teams.join(', '));
    }
    if(dateFrom == undefined){
      dateFrom = ''
    }
    if(dateTo == undefined){
      dateTo = ''
    }
    if(sortingBy == undefined){
      sortingBy = ''
    }
    if(sortingType == undefined){
      sortingType = ''
    }

    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/dffGameFixture/list?dffSeasonId=${seasonId}&type=${matchType}&pageNo=${pageNo}&limit=${limit}&match=all-matches&startDate=${dateFrom}&endDate=${dateTo}&sortBy=${sortingBy}&sortType=${sortingType}`,{params: this.params}).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public getCurrentSeason(): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/dffSeason/current`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public getGameDetailsByID(gameId: any): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/dffGameFixture/${gameId}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public userTeamsByGameID(gameId: any): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/userTeams/list?gameId=${gameId}&pageNo=1&limit=9999`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public getContestsByGameID(gameId: any): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/dffGameFixture/contestByPZ/${gameId}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public editGameById(id: any, payload: any): Observable<any> {
    let unassignFilterUrl = this.baseUrl.backendUrl + `/v1/admin/game/update/${id}`;
    return this.http.put<any>(`${unassignFilterUrl}`, payload)
      .pipe(
        retry(1),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      )
  }

  public simulateMatch(payload: any): Observable<any> {
    return this.http.post<any>(this.baseUrl.backendUrl + '/v1/admin/simulate', payload)
      .pipe(
        retry(1),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      )
  }

  public manuallyAssignMatch(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl.backendUrl}/v1/admin/game/create`, payload)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return;
          }
        },

          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          }),
        )
      )
  }

  public cloneContest(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl.backendUrl}/v1/admin/contest/clone`, payload)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return;
          }
        },

          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          }),
        )
      )
  }
  public getTeams(): Observable<any> {

    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/dffGameFixture/teamList`).pipe(
      catchError((err: HttpErrorResponse) => {

        return throwError(err);
      }),
    );
  }
  public getContestByGameId(id: any): Observable<any>{
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/contest/game/${id}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public getInstancesOfContest(id: any,teamName: any,teamId: any,instanceId: any,sortByPoint: any,sortByJoiningTime: any): Observable<any>{
    let apiUrl = this.baseUrl.backendUrl + `/v1/admin/leaderboards/instanceLeaderboard/${id}?`
    if(teamName){
      apiUrl += `teamNames=${teamName}`
    }
    if(teamId){
      apiUrl += `&teamIds=${teamId}`
    }
    if(instanceId){
      apiUrl += `&instanceIds=${instanceId}`
    }
    if(sortByPoint){
      apiUrl += `&sortByHeightPoint=${sortByPoint}`
    }
    if(sortByJoiningTime){
      apiUrl += `&sortByJoinTime=${sortByJoiningTime}`
    }
    return this.http.get(apiUrl).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public getLineup(id: any): Observable<any>{
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/dffGameFixture/${id}/lineups/`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public getTimeline(id: any): Observable<any>{
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/dffGameFixture/${id}/timeline`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public assignUnassignMatch(id: any,payload: any): Observable<any> {
    return this.http.put(`${this.baseUrl.backendUrl}/v1/admin/game/unassign/${id}`, payload)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return;
          }
        },

          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          }),
        )
      )
  }
  public syncMatch(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl.backendUrl}/v1/admin/optaCall`, payload)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return;
          }
        },

          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          }),
        )
      )
  }
  public updateMatchBudget(matchId:any, payload: any): Observable<any> {
    return this.http.put(`${this.baseUrl.backendUrl}/v1/admin/game/update/${matchId}`, payload)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return;
          }
        },

          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          }),
        )
      )
  }
  public activeInactiveContestnInstanceLock(contestId:any, payload: any): Observable<any> {
    return this.http.put(`${this.baseUrl.backendUrl}/v1/admin/contest/manipulate/gameContest/${contestId}`, payload)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return;
          }
        },

          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          }),
        )
      )
  }
  public attachContestInMatch(gameId: any,contestId:any, payload: any): Observable<any> {
    return this.http.put(`${this.baseUrl.backendUrl}/v1/admin/contest/game/${gameId}/${contestId}`, payload)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return;
          }
        },

          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          }),
        )
      )
  }
  public getUsersInMatch(MatchId: any): Observable<any>{
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/user/list?role=RFT_USER&_matchId=${MatchId}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public getTransactionsInMatch(MatchId: any): Observable<any>{
    return this.http.get(`${this.baseUrl.backendUrl}/v1/adminV2/rftBank/user/matchDetails/${MatchId}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
}
