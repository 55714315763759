<div class="pagination-limiter">
  <select
    class="select-box"
    [value]="(pn.$pagination | async)?.limit"
    (change)="changeLimit($event)"
    [disabled]="pn.isLoading"
  >
    <option *ngFor="let item of limitOptions" [value]="item">
      Show {{ item }}
    </option>
  </select>
  <span>Per page</span>
</div>

<div class="pagination-ctrl">
  <div class="spinner-grow text-dark" role="status" *ngIf="pn.isLoading">
    <span class="sr-only"></span>
  </div>
  <ng-content [select]="rightslot"></ng-content>
  <ng-container *ngIf="pn.data?.totalDocs > 0">
    <span>
      {{ pn.data?.pagingCounter }} -
      <ng-container
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{
          val: (pn?.data?.page ?? 0) * pn?.data?.limit,
          total: pn.data?.totalDocs
        }"
      ></ng-container>
      of
      {{ pn.data?.totalDocs }}
    </span>
  </ng-container>

  <button
    class="btn"
    (click)="changePage(pn.data.prevPage, pn.data?.limit)"
    [disabled]="!pn?.data?.hasPrevPage || pn?.isLoading"
  >
    <img
      width="18"
      height="18"
      class="opacity-45"
      src="../../../assets/left-arrow.png"
      alt=""
    />
  </button>
  <button
    class="btn"
    (click)="changePage(pn.data.nextPage, pn.data?.limit)"
    [disabled]="!pn?.data?.hasNextPage || pn?.isLoading"
  >
    <img
      width="18"
      height="18"
      class="opacity-45"
      src="../../../assets/right-arrow.png"
      alt=""
    />
  </button>
</div>

<ng-template #template let-val="val" let-total="total">
  {{ val <= total ? val : total }}
</ng-template>
