import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../settings.service';
import { FormBuilder } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
 @Component({
  selector: 'rft-script-button',
  templateUrl: './script-button.component.html',
  styleUrls: ['./script-button.component.scss']
})
export class ScriptButtonComponent implements OnInit {
  http: any;
  constructor(private fb: FormBuilder,
    private _settingsService: SettingsService,
    private toastr: ToastrService,) { }
  season: number = environment.currentSeason;
  leagueList: Array<any>
  searchForm: any;
  madList: any;
  services = ["DFF", "SFF"]
  isLeagueAvailable = false
  isMdlistAvailable = false
  isFilterOpen = false
  isApplyNotAvailble = true

  close() {
    this.isFilterOpen = !this.isFilterOpen
    this.searchForm.patchValue({
      services: 'SFF',
      matchDay: 1,
      selectedLeague: 8,
    })
  }
  apply(): void {
    const body = {...this.searchForm.value, services: [this.searchForm.get("services").value]}
    const toBeSentBoy  = {
      services: [this.searchForm.get("services").value],
      leagueId: body.selectedLeague,
      matchDayNumber: body.matchDay

    }
    this._settingsService.fixMasterScript(toBeSentBoy).subscribe(res=>{
      this.toastr.success(
        `${res.message}`,
        'RFT NOTIFICATION'
      );
    this.isFilterOpen = !this.isFilterOpen
    this.searchForm.patchValue({
      services: 'SFF',
      matchDay: 1,
      selectedLeague: 8,
    })

    })
  }
  getLeagues() {
    return this._settingsService.getLeagueList()
  }
  getPrizeZone() {
    return this._settingsService.getPrizeZoneList()
  }
  callThirdApiWithCombinedData(val?: any): any {
    // Call the first and second APIs concurrently using forkJoin
    return forkJoin([
      this.getLeagues(),
      this.getPrizeZone()
    ]).pipe(
      map(([firstApiResponse, secondApiResponse]) => {
        // Combine data from first and second APIs
        this.leagueList = firstApiResponse.data.items.filter((e: { lid: any; }) => e.lid !== 4).map((e: { lid: any; }) => e.lid)
        const combinedData = {
          leagues: firstApiResponse.data.items,
          prizezone: secondApiResponse.data.items.filter((e: { name: string; }) => e.name === "India")[0]._id
        };
        return combinedData;
      }),
      // Call the third API with combined data
      switchMap((combinedData) => {
        return this._settingsService.getSffMatchDays(this.season, val || combinedData.leagues[0].lid, combinedData.prizezone, 1, 40)
      })
    )
  }

  openCloseFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      services: 'SFF',
      matchDay: 1,
      selectedLeague: 8,
    });
    this.searchForm.get('selectedLeague').valueChanges.subscribe((value: any) => {

      this.isMdlistAvailable = false
      this.isApplyNotAvailble = true


      this.callThirdApiWithCombinedData(value).subscribe((res: any) => {
        this.madList = res.data.items
        this.isLeagueAvailable = true
        this.isMdlistAvailable = true
        this.isApplyNotAvailble = false
      })
      // Call a method or trigger an action here
    });
    this.callThirdApiWithCombinedData().subscribe((res: any) => {
      this.madList = res.data.items
      this.isLeagueAvailable = true
      this.isMdlistAvailable = true
      this.isApplyNotAvailble = false
    })
  }


}


