import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from './../../../environments/environment';
import { generateState } from '../utils/string'
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private openId = environment.openId;
  // countryCode: any;
  constructor(private _authService: AuthService, private route: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   if (this._authService.isLogin()) {
      return true;
    }
    fetch('https://api.ipregistry.co/?key=tryout')
    .then(function (response) {
        return response.json();
    })
    .then( (payload) => {
        // console.log(payload.location.country.name + ', ' + payload.location.city);
       const countryCode = payload.location.country.calling_code;
       let states: string = generateState(30);
       localStorage.setItem('openIdState', states);
       localStorage.setItem('response_type', 'authcode');
       localStorage.setItem('openid', 'true');
       window.location.href = `${this.openId.opUrl}?scope=${this.openId.scope}&responseType=${this.openId.response_type}&clientId=${this.openId.clientId}&redirectUri=${this.openId.redirect_url}&state=${states}&countryCode=${countryCode}`;
    }).catch(err=>{
      const countryCode = 91;
      let states: string = generateState(30);
      localStorage.setItem('openIdState', states);
      localStorage.setItem('response_type', 'authcode');
      localStorage.setItem('openid', 'true');
      window.location.href = `${this.openId.opUrl}?scope=${this.openId.scope}&responseType=${this.openId.response_type}&clientId=${this.openId.clientId}&redirectUri=${this.openId.redirect_url}&state=${states}&countryCode=${countryCode}`;
    });
   
    return false;
  }
  
}


