import { Clipboard } from '@angular/cdk/clipboard';
import { Directive, HostListener, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[rftCopyToClipboard]',
})
export class CopyToClipboardDirective {
  @Input('text') text!: string;
  @Input('notificationText') notificationText = 'Copied!';

  constructor(private cdkCopy: Clipboard, private toastr: ToastrService) {}

  @HostListener('click', ['$event'])
  copyText(e: any) {
    e.stopPropagation();
    this.cdkCopy.copy(this.text);
    if (this.notificationText)
      this.toastr.success(this.notificationText, 'RFT NOTIFICATION');
  }
}
