export const menuData = [
    {
        "id": 1,
        "mainMenuLabel": "Settings",
        "icon": "../../../assets/setting.svg",
        "selectedIcon": "../../../assets/setting-fill.svg",
        "mainMenuPermission": "settings",
        "root": "settings",
        "path": "settings",
        "submenu": [
            {
                "submenuLabel": "Default",
                "mainMenuLabel": "Settings",
                "icon": "../../../assets/default.svg",
                "path": "settings/core",
                "subMenuPermission": "settings.core",
                "insiderMenu": [
                    {
                        "insiderMenuPermission": "settings.core.prizone",
                        "insiderMenuLabel": "Prize Zones",
                        "insiderMenuPath": "settings/core/prizezones"
                    }
                ]
            },
            {
                "submenuLabel": "Leagues",
                "mainMenuLabel": "Settings",
                "icon": "../../../assets/league-black.png",
                "path": "settings/leagues",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            },
            {
                "submenuLabel": "Payout Gateway",
                "mainMenuLabel": "Settings",
                "icon": "../../../assets/payout-gateways-black.png",
                "path": "settings/payout-gateways",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            },
            {
                "submenuLabel": "Payment Gateway",
                "mainMenuLabel": "Settings",
                "icon": "../../../assets/payment-gateways-black.png",
                "path": "settings/payment-gateways",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            }
        ]
    },
    {
        "id": 2,
        "mainMenuLabel": "DFF",
        "icon": "../../../assets/dff.svg",
        "selectedIcon": "../../../assets/dff-fill.svg",
        "mainMenuPermission": "dff",
        "root": "settings",
        "path": "dff",
        "submenu": [
            {
                "submenuLabel": "Default",
                "mainMenuLabel": "DFF",
                "icon": "../../../assets/default.svg",
                "path": "dff/core",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            },
            {
                "submenuLabel": "Matches",
                "mainMenuLabel": "DFF",
                "icon": "../../../assets/core.png",
                "path": "dff/match-management",
                "subMenuPermission": "dff.matches",
                "insiderMenu": [
                ]
            }
        ]
    },
    {
        "id": 3,
        "mainMenuLabel": "SFF",
        "icon": "../../../assets/dff.svg",
        "selectedIcon": "../../../assets/dff-fill.svg",
        "mainMenuPermission": "dff",
        "root": "settings",
        "path": "sff",
        "submenu": [
            {
                "submenuLabel": "Season",
                "mainMenuLabel": "SFF",
                "icon": "../../../assets/default.svg",
                "path": "sff/seasons-list",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            },
            // {
            //     "submenuLabel": "User Teams",
            //     "mainMenuLabel": "SFF",
            //     "icon": "../../../assets/team-1.png",
            //     "path": "sff/user-teams",
            //     "subMenuPermission": "dff",
            //     "insiderMenu": [
            //     ]
            // },
            {
                "submenuLabel": "RFT Match Days",
                "mainMenuLabel": "SFF",
                "icon": "../../../assets/calendar-1.png",
                "path": "sff/rft-matchdays",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            }
        ]
    },
    {
        "id": 4,
        "mainMenuLabel": "Accounts",
        "icon": "../../../assets/bank.svg",
        "selectedIcon": "../../../assets/bank-fill.svg",
        "mainMenuPermission": "dff",
        "root": "settings",
        "path": "accounts",
        "submenu": [
            {
                "submenuLabel": "Payments",
                "mainMenuLabel": "Accounts",
                "icon": "../../../assets/payment.svg",
                "path": "accounts/payment",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            },
            {
                "submenuLabel": "Payouts",
                "mainMenuLabel": "Accounts",
                "icon": "../../../assets/payout.svg",
                "path": "accounts/payout",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            },
            {
                "submenuLabel": "Transactions",
                "mainMenuLabel": "Accounts",
                "icon": "../../../assets/transaction.svg",
                "path": "accounts/user-transactions",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            }
        ]
    },
    {
        "id": 5,
        "mainMenuLabel": "Users",
        "icon": "../../../assets/user.svg",
        "selectedIcon": "../../../assets/user-fill.svg",
        "mainMenuPermission": "dff",
        "root": "users",
        "path": "users",
        "submenu": [
            {
                "submenuLabel": "Users",
                "mainMenuLabel": "Users",
                "icon": "../../../assets/users.svg",
                "path": "users/users-list",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            }
        ]
    },
    {
        "id": 6,
        "mainMenuLabel": "Assets",
        "icon": "../../../assets/assets.svg",
        "selectedIcon": "../../../assets/assets-fill.svg",
        "mainMenuPermission": "dff",
        "root": "assets",
        "path": "assets",
        "submenu": [
            {
                "submenuLabel": "Players",
                "mainMenuLabel": "Assets",
                "icon": "../../../assets/player.svg",
                "path": "assets/players",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            },
            {
                "submenuLabel": "Clubs",
                "mainMenuLabel": "Assets",
                "icon": "../../../assets/club-black.png",
                "path": "assets/clubs",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            }
        ]
    },   
    {
        "id": 7,
        "mainMenuLabel": "Notification",
        "icon": "../../../assets/notification.png",
        "selectedIcon": "../../../assets/notification.png",
        "mainMenuPermission": "dff",
        "root": "settings",
        "path": "notification",
        "submenu": [
            {
                "submenuLabel": "global-notification",
                "mainMenuLabel": "Notification",
                "icon": "../../../assets/player.svg",
                "path": "notification/GlobalNotification",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            },
            // {
            //     "submenuLabel": "subMenu2",
            //     "mainMenuLabel": "Notification",
            //     "icon": "../../../assets/club-black.png",
            //     "path": "notification/subMenu2",
            //     "subMenuPermission": "dff",
            //     "insiderMenu": [
            //     ]
            // }
        ]
    },
    {
        "id": 8,
        "mainMenuLabel": "subscription",
        "icon": "../../../assets/subscribe.png",
        "selectedIcon": "../../../assets/assets-fill.svg",
        "mainMenuPermission": "dff",
        "root": "settings",
        "path": "subscription",
        "submenu": [
            {
                "submenuLabel": "RFTC",
                "mainMenuLabel": "subscription",
                "icon": "../../../assets/player.svg",
                "path": "subscription/RFTC",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            },
            {
                "submenuLabel": "League-Pack",
                "mainMenuLabel": "subscription",
                "icon": "../../../assets/player.svg",
                "path": "subscription/leaguePack",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            }
        ]
    },
    {
        "id": 9,
        "mainMenuLabel": "survey",
        "icon": "../../../assets/survey.png",
        "selectedIcon": "../../../assets/assets-fill.svg",
        "mainMenuPermission": "dff",
        "root": "settings",
        "path": "survey",
        "submenu": [
            {
                "submenuLabel": "questionnaire",
                "mainMenuLabel": "survey",
                "icon": "../../../assets/player.svg",
                "path": "survey/questionnaire",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            },
            {
                "submenuLabel": "question",
                "mainMenuLabel": "survey",
                "icon": "../../../assets/player.svg",
                "path": "survey/question",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            },
        ]
    },
    {
        "id": 10,
        "mainMenuLabel": "Refer & Earn",
        "icon": "../../../assets/survey.png",
        "selectedIcon": "../../../assets/default.svg",
        "mainMenuPermission": "dff",
        "root": "settings",
        "path": "refer-earn",
        "submenu": [
            {
                "submenuLabel": "RFT Referral",
                "mainMenuLabel": "Refer & Earn",
                "icon": "../../../assets/player.svg",
                "path": "refer-earn/refer-list",
                "subMenuPermission": "dff",
                "insiderMenu": [
                ]
            }
        ]
    },
]