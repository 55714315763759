import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../settings.service';

@Component({
  selector: 'rft-payout-gateways',
  templateUrl: './payout-gateways.component.html',
  styleUrls: ['./payout-gateways.component.scss']
})
export class PayoutGatewaysComponent implements OnInit {
  isFilterOpen: boolean = false;
  isPayoutList: boolean = false;
  isPayoutGatewaysLoaded: boolean = false;
  payoutGatewaysList: any = [];
  constructor(private _settingsService: SettingsService,private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getPayoutGateways();
  }
  public getPayoutGateways() {
    this.isPayoutGatewaysLoaded = false;
    this._settingsService.getLeagueList().subscribe(res => {
      console.log("Payout Gateways =>",res.data.items);
      this.payoutGatewaysList = res.data.items;
      this.isPayoutList = true;
      this.isPayoutGatewaysLoaded = true;
    }, error => {
      this.toastr.error(error.message, 'RFT NOTIFICATION');
    })
  }
  public payoutGatewayDetails(){
    this.router.navigate(['/settings/league-details'])
  }
  public createPayoutGateway(){
    this.router.navigate(['/settings/payout-gateway-create'])
  }
  openFilter() {
    this.isFilterOpen = true;
  }
  clearFilter() {
    this.isFilterOpen = false;
  }
  applyFilter() {
    this.isFilterOpen = false;
  }
}
