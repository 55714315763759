import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingsService } from '../../../settings/settings.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, map } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/classes/unsubscriber';
@Component({
  selector: 'rft-pz-tab-bar',
  templateUrl: './pz-tab-bar.component.html',
  styleUrls: ['./pz-tab-bar.component.scss']
})

export class PzTabBarComponent extends Unsubscriber implements OnInit {
  prizeZoneList: Array<any>
  isPrizeZoneLoaded: Boolean = false
  selectedPz: string
  restPrizeZones: any[]
  @Input () heading : string
  @Output() emittedValues: EventEmitter<{ rest: any, current: any }> = new EventEmitter()

  constructor(private _settingsService: SettingsService, private toastr: ToastrService) {
    super();
  }

  activeTab: any;
  public handleTab(element: any): void {
    this.activeTab = { name: element.name, _id: element._id, currency: element.currency }

    this.restPrizeZones = this.prizeZoneList.filter(e => e.name !== this.activeTab.name)
    this.emittedValues.emit({ rest: this.restPrizeZones, current: this.activeTab })

  }

  ngOnInit(): void {
    this._settingsService.getPrizeZoneList().subscribe(res => {
      takeUntil(this.$destroyRef),
        this.prizeZoneList = res.data.items.map((e: any) => {
          return {
            name: e.name,
            _id: e._id,
            currency: e.effectiveCurrency
          }
        })

      this.activeTab = { name: this.prizeZoneList[0].name, _id: this.prizeZoneList[0]._id, currency: this.prizeZoneList[0].currency }
      this.restPrizeZones = this.prizeZoneList.filter(e => e.name !== this.activeTab.name)
      this.emittedValues.emit({ rest: this.restPrizeZones, current: this.activeTab })


      if (!this.prizeZoneList?.length) {
        this.isPrizeZoneLoaded = false;
      }
      this.isPrizeZoneLoaded = true;



    }, error => {
      this.toastr.error(error.message, 'RFT NOTIFICATION');
    })





  }

}
