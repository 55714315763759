import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedRoutingModule } from './shared-routing.module';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { PzTabBarComponent } from './sharedPzTab/pz-tab-bar/pz-tab-bar.component';


@NgModule({
  declarations: [
    UnderConstructionComponent,
    PzTabBarComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgxSkeletonLoaderModule,

  ],
  exports: [UnderConstructionComponent,PzTabBarComponent]
})
export class SharedModule { }
