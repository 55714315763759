<div class="container-fluid">
  <div class="row">
    <div class="col">
      <p class="mt-3"><b class="header-font">Default</b></p>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <rft-script-button></rft-script-button>

    </div>


  </div>
  <div class="row">
    <div class="col-12">
      <div *ngIf="$activeTab | async as activeTab">
        <ul class="nav nav-tabs">
          <li class="nav-item" *ngFor="let item of tabs; let i = index">
              
              <button class="nav-link"
              [routerLink]="['/settings/core']"
              [queryParams]="i > 0 ? { tab: item } : {}"
              [class.active]="activeTab.active === item"
            >
              {{ item }}
            </button>
          </li>
        </ul>

        <ng-container [ngSwitch]="activeTab.active">
          <div *ngSwitchCase="tabs[1]">
            <rft-leagues></rft-leagues>
          </div>
          <div *ngSwitchDefault>
            <rft-prize-zones> </rft-prize-zones>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
