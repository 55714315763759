import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { generateState } from '../core/utils/string';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl: any;
  bytes: any;
  decryptedData: any;
  permissions: any;
  profileData: any;
  private openId = environment.openId;
  encryptedpermissions: any;
  menuPermission: any = [];
  allMenuPermission: any;
  constructor(private http: HttpClient, private route: Router) { }

  public isLogin() {
    if (localStorage.getItem('accessToken')) {
      return true;
    } else {
      return false;
    }
  }

  public loggedOut() {
    let token = localStorage.getItem('accessToken');
    let state = generateState(30);
    localStorage.clear();
    localStorage.setItem('logOutState', state);
    window.location.href = `${this.openId.logoutEndpoint}?accessToken=${token}&redirectUri=${this.openId.redirect_url}&clientId=${this.openId.clientId}`;

  }

  public token(payload: any, tokenSecret: any): Observable<any> {
    let headers = new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded; charset=UTF-8').set('x-access-token', tokenSecret);
    return this.http.post(`${environment.openId.tokenEndpoint}`, payload, { headers })
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }
        },

          catchError((err) => {
            this.loggedOut();
            return throwError(err);

          }),
        )
      )

  }
  public callPermissionApi() {
    const token = localStorage.getItem('accessToken');
    let permissionApi = `${this.openId.accountsUrl}/v1/permission/mine`
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }
    this.http.get(permissionApi, header).subscribe((response: any) => {
      localStorage.setItem('permission', JSON.stringify(response['data'].items));
      this.permissions = response['data'].items;
      this.menuPermission = [];
      this.permissions.map((x: any) => {


        let len = x.split(".").length
        for (let i = 1; i <= len + 1; i++) {
          let el = x.split(".", i).join(".")
          this.menuPermission.push(el)
        }

      });
      this.allMenuPermission = [... new Set(this.menuPermission)];
      console.log(this.permissions);
    })

  }
  public callpromise() {
    let promise = new Promise<void>((resolve, reject) => {

      this.callPermissionApi()
      this.callMeApi()


    }
    ).then(() => {
      console.log("CallPromise resolved")

    }
    );

  }

  public callLogin() {
    let states: string = generateState(30);
    localStorage.setItem('openIdState', states);
    localStorage.setItem('response_type', 'authcode');
    localStorage.setItem('openid', 'true');
    window.location.href = `${this.openId.opUrl}?scope=${this.openId.scope}&responseType=${this.openId.response_type}&clientId=${this.openId.clientId}&redirectUri=${this.openId.redirect_url}&state=${states}`;

  }

  public callMeApi() {
    const token = localStorage.getItem('accessToken');
    let meApi = `${this.openId.accountsUrl}/v1/users/me`
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }
    this.http.get(meApi, header).subscribe((response: any) => {
      this.profileData = response;

      localStorage.setItem('profile', JSON.stringify(response['data'].items));
      console.log(this.profileData.data.items[0].roleCode);
      if (this.profileData.data.items[0].roleCode == "SUPER_ADMIN") {
        let y = JSON.parse(JSON.stringify(localStorage.getItem('permission')));
        const permissions = JSON.parse(y);
        this.menuPermission = [];
        if(permissions != null){
        permissions.oldPermission.map((x: any) => {
          let len = x.split(".").length
          for (let i = 1; i <= len + 1; i++) {
            let el = x.split(".", i).join(".")
            this.menuPermission.push(el)
          }

        });
        }
        let allMenuPermissions = [];
        allMenuPermissions = [... new Set(this.menuPermission)];
        if (allMenuPermissions?.length != 0) {
          console.log(allMenuPermissions)
          this.route.navigate(['settings/core'])
            .then(() => {
              window.location.reload();
            });
        }

        // window.location.reload();
      }


    })

  }
  public callRefreshToken(): Observable<any> {

    const x: any = localStorage.getItem('profile');
    const y: any = localStorage.getItem('accessToken');
    const z = localStorage.getItem('refreshToken');
    const payload = {
      refreshToken: z
    }   
    // if (!x) {
    //   // If 'profile' is not found in localStorage, return an observable error.
    //   return throwError('Profile not found in localStorage');
    // }
   
    return this.http.post(`${environment.openId.accountsUrl}/v1/auth/idToken/refresh`, payload,{headers:{'x-user-id': JSON.parse(x)[0].id,'x-access-token': y.toString()}})
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }
        },
          catchError((err) => {
            
            this.loggedOut();
            return throwError(err);

          }),
        )
      )
  }

  public checkPermission(permission: String) {
    // this.setPermission();
    if (this.allMenuPermission.findIndex((s: any) => s == permission) != -1) {
      return true;
    }
    else {

      return false;
    }
  }
}
