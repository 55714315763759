import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public playerAsset: any;
  private baseUrl = environment.openId;
  params: any;
  constructor(private http: HttpClient) { }

  public getCountryList(): Observable<any> {

    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/country/list`).pipe(
      catchError((err: HttpErrorResponse) => {

        return throwError(err);
      }),
    );
  }

  public getPaymentPayoutGateway(gatewayType: any): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/adminV2/rftBank/gateway?gatewayType=${gatewayType}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }

  public getSffMatchDays(season: any,league: any,prizeZone: any,pageNo: any, limit: any): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/sff/mdPrizeZone?season=${season}&league=${league}&prizeZone=${prizeZone}&pageNo=${pageNo}&limit=${limit}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }


  public getPrizeZoneList(): Observable<any> {

    return this.http.get(`${this.baseUrl.backendUrl}/v1/adminV2/prizeZone/list`).pipe(
      catchError((err: HttpErrorResponse) => {

        return throwError(err);
      }),
    );
  }
  public fixMasterScript(payload:any): Observable<any>{
    return this.http.post(`${this.baseUrl.backendUrl}/v1/admin/scripts/playerPointFix`, payload)
    .pipe(
      map((response) => {
        if (response) {
          return response;
        } else {
          return;
        }
      },

        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        }),
      )
    )
  }

  public getPrizeZoneById(id: any): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/adminV2/prizeZone/` + id)
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }
        },
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          }),
        )
      )
  }

  public addPrizeZone(payload: any): Observable<any> {

    return this.http.post(`${this.baseUrl.backendUrl}/v1/adminV2/prizeZone`, payload)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return;
          }
        },

          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          }),
        )
      )
  }

  public getLeagueList(): Observable<any> {
    // status=true
    return this.http.get(`${this.baseUrl.backendUrl}/v1/adminV2/league/list?`).pipe(
      catchError((err: HttpErrorResponse) => {

        return throwError(err);
      }),
    );
  }
  public getTeamsList(leagueId: any = [],currentSeason: any): Observable<any> {
    // this.params = new HttpParams();
    // this.params = this.params.append('leagueId', leagueId.join(', '));
    // teamRef.forEach((team:String) => {
    //   unassignFilterUrl += `&teamRefs=${team}`;
    // });
    let teamUrl = this.baseUrl.backendUrl + `/v1/admin/assets/player/teamFilter?season=${currentSeason}`;
    leagueId.forEach((league: String) => {
      teamUrl += `&leagueId=${league}`;
    });
   

    return this.http.get(`${teamUrl}`).pipe(
      catchError((err: HttpErrorResponse) => {

        return throwError(err);
      }),
    );
  }
  public getPlayerAssets(season: any, limit: any, pageNo: any, search: any, leagueId: [], teamRef: []): Observable<any> {
    let apiUrl = this.baseUrl.backendUrl + `/v1/admin/assets/player/list?pageNo=${pageNo}&limit=${limit}&season=${season}&playerName=${search}`;
    if (leagueId?.length > 0) {
      leagueId.forEach((league: any) => {
        apiUrl += `&leagueId=${league}`;
      });
    }
    if (teamRef?.length > 0) {
      teamRef.forEach((team: String) => {
        apiUrl += `&teamRef=${team}`;
      });
    }

    return this.http.get(`${apiUrl}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public getPlayerTimeline(season: any, playerRef: any): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/assets/player/timeline?season=${season}&playerRef=${playerRef}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public getCurrentSeason(): Observable<any> {
    return this.http.get(`${this.baseUrl.backendUrl}/v1/admin/dffSeason/current`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }
  public getExportCsv(season: any, search: any, leagueId: [], teamRef: []): Observable<any> {
    let apiUrl = this.baseUrl.backendUrl + `/v1/admin/csv/create?season=${season}&playerName=${search}`;
    if (leagueId?.length > 0) {
      leagueId.forEach((league: any) => {
        apiUrl += `&leagueId=${league}`;
      });
    }
    if (teamRef?.length > 0) {
      teamRef.forEach((team: String) => {
        apiUrl += `&teamRef=${team}`;
      });
    }

    return this.http.get(`${apiUrl}`).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }

  public uploadXlsx(file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${this.baseUrl.backendUrl}/v1/admin/assets/player/updateBySheet`, formData)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            return;
          }
        },

          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          }),
        )
      )
  }
}
