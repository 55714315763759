<div class="container-fluid">
  <div class="row mt-3">
    <div class="col fw-bold fs-16">
      <img [routerLink]="['/settings/payout-gateways']" src="../../../assets/back-arrow.png"
        class="hw-26 cursor-pointer" alt="" />
      <label class="ps-3"> Create Payout Gateway</label>
    </div>
  </div>
  <div class="card mt-4 border-0 card-height">
    <div class="card-body">
      <div class="row" [formGroup]="payoutGatewayForm">
        <div class="col-3">
          <mat-form-field appearance="outline">
            <mat-label>Gateway Name</mat-label>
            <input matInput placeholder="Ex. CashFree" formControlName="gatewayName">
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="outline">
            <mat-label>Gateway Type</mat-label>
            <input matInput placeholder="Ex. Payout" value="Payout" disabled>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input matInput placeholder="Ex. Active" value="Inactive" disabled>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="card-footer text-end bg-white border-0 pb-5">
      <button type="button" class="btn btn-sm btn-secondary me-2 ">Cancel</button>
      <button type="button" class="btn btn-sm btn-danger">Create Payout Gateway</button>
    </div>
  </div>
</div>