import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(private _authService: AuthService) { }
  public refreshToken() {
    this._authService.callRefreshToken().subscribe((res: any) => {
      const refreshTokenNew = res.data.items[0].refreshToken;
      const accessTokenNew = res.data.items[0].accessToken;
      localStorage.setItem('accessToken', accessTokenNew);
      localStorage.setItem('refreshToken', refreshTokenNew);
      window.location.reload();
    }, error => {
      console.log(error);
      console.log(error.message);
      localStorage.clear();
      this._authService.callLogin();
    });
  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let errorMessage: string | null;
    return next.handle(request).pipe(
      catchError((response) => {
        if (response instanceof HttpErrorResponse) {
          try {
            errorMessage = `An error occurred:${response.error.message}`
          } catch {
            errorMessage = null;
          }
        }
        switch (response.status) {
          case 404:
            errorMessage = "Not Found";
            break;
          case 401:
            this.refreshToken();
            errorMessage = "Forbidden";
            break;
          default:
            break;
        }
        return throwError(response.error);
      }),
    );
  }
}
