<div class="container-fluid">
    <div class="row mb-20">
      <div class="col">
        <p class="mt-3"><b class="header-font">{{heading}}</b></p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 ">
        <ngx-skeleton-loader *ngIf="!isPrizeZoneLoaded" count="1" appearance="line" animation="progress"> </ngx-skeleton-loader>

        <div *ngIf="activeTab?.name">
          <ul class="nav nav-tabs">

            <li class="nav-item mr-56" *ngFor="let item of prizeZoneList; let i = index" #tab>
             
              <button class="nav-link" 
              (click)="handleTab(item)"
                [class.active]="activeTab.name === item.name"
              >


                {{ item.name }}
                
              </button>
            </li>
          </ul>
  
        </div>
      </div>
    </div>
  </div>
  