import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { FlowService } from 'src/app/flow.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private auth:AuthService,private router:Router,private _flowService:FlowService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // const redirectUrl = next['_routerState']['url'];
      if(this.auth.isLogin()) {
        const UserRole= this._flowService.getPermission();
        if (UserRole.findIndex((s:any)=>s==next.data.role) === -1) {
          console.log(next.data.role);
          this.router.navigate(['dashboard/profile-details']);
          return false;
        }
        return true;
      } else{
        return false;
      }
  }
  
}
