import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class FlowService {
  bytes: any;
  permissions: any = [];
  permission: any = [];
  constructor() { }
  public getPermission() {
    let permission: any = localStorage.getItem('permission') !== null ? localStorage.getItem('permission') : 'U2FsdGVkX19FBkPR5IYP+zmf8i8SwrGDIMzIp9IMXevVL6fHd5pliehJGrQCAZLjoGAHyHmYHHarMe9LS2gwdiKTdpkayX3wKICPo/6p507WbT5T0fUkTnNSSNwiCsJBLaip/V7IgAzbDYxJ3w6JXdAPBjkpFOaif0Lxx39ektWEkivOFuwPTCQcuXaIim4WIKlEmcdMvpWmjYvn4OZO1msDE8AGx9EhxnYGLa91Ijg9tsWls1p65r5Kp65fJmRrOjhrWDgfqhEeX1yfdNWmD9JVIcK9wK/1UPdrA+OppnIDs0Cu0I6usXFbutcF0utxqHs17SgJmOqcQBVjwZOB6MW2DDLrLYvsmjZOnfexYZ2hXOWTxSPzi35G1ec9tB/G';
    // this.bytes = CryptoJS.AES.decrypt(permission, 'dvwfFVM64kbCklE7xoip3ISx0IoSZ6');
    // this.permissions = JSON.parse(this.bytes.toString(CryptoJS.enc.Utf8));
    let y = JSON.parse(JSON.stringify(localStorage.getItem('permission')));
    this.permissions = JSON.parse(y) ;
    // this.permissions = JSON.parse(permission);
    return this.permissions;
  }
  public setPermission() {
    this.permission = this.getPermission();

  }
  public hasPermissions() {
    this.setPermission();
    if (this.permission) {
      return true;
    }
    else {
      return false;
    }

  }
  public checkPermission(permission: String) {
    this.setPermission();
    if (this.permission.findIndex((s: any) => s == permission) != -1) {
      return true;
    }
    else {

      return false;
    }
  }
}
