import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './../auth.service';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { generateState } from 'src/app/core/utils/string';
@Component({
  selector: 'rft-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  auth_code: any;
  state: any;
  isLogout: string | undefined;
  access_token: any;
  tokenSecret: any;
  mobileNumber: any;
  private openId = environment.openId;
  decryptedData: any;
  constructor(private ativatedRoute: ActivatedRoute,
    private router: Router,
    private _authservice: AuthService, private _authService: AuthService) { }

  public ngOnInit() {
    this.state = localStorage.getItem('openIdState');
    let response_type = localStorage.getItem('response_type');
    let openId = localStorage.getItem('openid');
    if ((response_type === 'authcode')) {
      if(this.ativatedRoute.snapshot.queryParams['userExists']){
        this.mobileNumber = this.ativatedRoute.snapshot.queryParams['phone'];
        localStorage.setItem('response_type','loginWithOTP');
        window.location.href = `${this.openId.opMobileUrl}?scope=${this.openId.scope}&responseType=${this.openId.response_type}&redirectUri=${this.openId.redirect_url}&state=${this.state}&phone=${this.mobileNumber}&apiKey=${this.openId.clientId}`;
      }
      if (!this.ativatedRoute.snapshot.queryParams['userExists']) {
        this.auth_code = this.ativatedRoute.snapshot.queryParams['auth_code'];
        this.tokenSecret = this.ativatedRoute.snapshot.queryParams['tokenSecret']
        let newstate: string = generateState(30);

        localStorage.setItem('response_type', 'token');
        localStorage.removeItem('openIdState');
        const payload = {
          "auth_code": this.auth_code,
          "state": this.state,
          "redirectUri": this.openId.redirect_url,
          "client_secrete": this.openId.clientSecret
        }

        const payloads = new HttpParams()
          .set('auth_code', this.auth_code)
          .set('state', this.state)
          .set('redirectUri', this.openId.redirect_url)
          .set('client_secrete', this.openId.clientSecret);
        console.log(payload)
        this._authService.token(payloads,this.tokenSecret)
          .subscribe((data: any) => {
            this.access_token = data.data.items[0].accessToken;
            let refresh_token = data.data.items[0].refreshToken;
            localStorage.setItem('refreshToken', refresh_token);
            localStorage.setItem('accessToken', this.access_token);
            localStorage.removeItem('response_type');
            localStorage.removeItem('openid');
            this._authservice.callpromise();
            this.router.navigate(['settings/core']).then(()=>{
            
              // to delay the window reload
              setTimeout(()=>{
                window.location.reload();
              },1000)

              
            });
            
          }, (error: any) => {
            console.log(error);
          });
      }

    }
    if(response_type === 'loginWithOTP'){
        this.auth_code = this.ativatedRoute.snapshot.queryParams['auth_code'];
        this.tokenSecret = this.ativatedRoute.snapshot.queryParams['tokenSecret']
        let newstate: string = generateState(30);

        localStorage.setItem('response_type', 'token');
        localStorage.removeItem('openIdState');
        const payload = {
          "auth_code": this.auth_code,
          "state": this.state,
          "redirectUri": this.openId.redirect_url,
          "client_secrete": this.openId.clientSecret
        }

        const payloads = new HttpParams()
          .set('auth_code', this.auth_code)
          .set('state', this.state)
          .set('redirectUri', this.openId.redirect_url)
          .set('client_secrete', this.openId.clientSecret);
        console.log(payload)
        this._authService.token(payloads,this.tokenSecret)
          .subscribe((data: any) => {
            this.access_token = data.data.items[0].accessToken;
            let refresh_token = data.data.items[0].refreshToken;
            localStorage.setItem('refreshToken', refresh_token);
            localStorage.setItem('accessToken', this.access_token);
            localStorage.removeItem('response_type');
            localStorage.removeItem('openid');
            this._authservice.callpromise();
            this.router.navigate(['settings/core']).then(()=>{
              window.location.reload();
            });
          }, (error: any) => {
            console.log(error);
          });
    }
    let state = localStorage.getItem('logOutState');

    if ((this.ativatedRoute.snapshot.queryParams['logout'] === 'true') ) {

      localStorage.removeItem('logOutState');
      window.localStorage.clear();
      this.router.navigate(['dashboard/profile-details']);

    }
  }

}
