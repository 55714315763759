import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/settings/settings.service';

@Component({
  selector: 'rft-payout-gateway-create-view',
  templateUrl: './payout-gateway-create-view.component.html',
  styleUrls: ['./payout-gateway-create-view.component.scss']
})
export class PayoutGatewayCreateViewComponent implements OnInit {
  public payoutGatewayForm: any;
  public isNoDataFound: boolean = false;
  constructor(private fb: FormBuilder, private _settingsService: SettingsService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.payoutGatewayForm = this.fb.group({
      gatewayName: ['', Validators.required],
      gatewayType: ['payout', [Validators.required]],
      isActive: [false, [Validators.required]]
    });
  }

}
