// const hostUrl = "https://devadminapi.realfantasyteams.com/adminService"
// const authHostUrl = "https://devuser.realfantasyteams.com/rft-accounts"
// const sffHostUrl = "https://devsff.realfantasyteams.com/sffService"
// export const environment = {
//   production: false,
//   hostUrl: hostUrl,
//   sffHostUrl: sffHostUrl,
//   openId: {
//     accountsUrl: authHostUrl,
//     backendUrl:hostUrl,
//     clientId: 'GCMUDiuY5a7WvyUNt9n3QztToSHnRD5S',
//     clientSecret:'Jsjw3hsH5WIcS5niwshi5hissgt4eb',
//     redirect_url: `${window.location.origin}/auth/redirect`,
//     response_type: 'code',
//     opUrl: `${authHostUrl}/auth/authorize`,
//     opMobileUrl: `${authHostUrl}/auth/openIdLogin/login`,
//     scope: 'openid',
//     tokenEndpoint: `${authHostUrl}/auth/openIdLogin/token`,
//     logoutEndpoint:`${authHostUrl}/auth/openIdLogin/logout`,
//   },
//   defaultSeason: 2023
// };





const hostUrl = "https://devadminapi.realfantasyteams.com/adminService"
const authHostUrl = "https://devuser.realfantasyteams.com/rft-accounts"
const sffHostUrl = "https://devsff.realfantasyteams.com/sffService"
export const environment = {
  production: false,
  hostUrl: hostUrl,
  sffHostUrl: sffHostUrl,
  openId: {
    accountsUrl: authHostUrl,
    backendUrl:hostUrl,
    clientId: 'GCMUDiuY5a7WvyUNt9n3QztToSHzK7Uj',
    clientSecret:'Jsjw3hsH5WIcS5niwshi5hiss5shIsh',
    redirect_url: `${window.location.origin}/auth/redirect`,
    response_type: 'code',
    opUrl: `${authHostUrl}/auth/authorize`,
    opMobileUrl: `${authHostUrl}/auth/openIdLogin/login`,
    scope: 'openid',
    tokenEndpoint: `${authHostUrl}/auth/openIdLogin/token`,
    logoutEndpoint:`${authHostUrl}/auth/openIdLogin/logout`,
  },
  currentSeason: 2024,
  seasonList: [2025, 2024, 2023, 2022, 2021, 2020]
};
