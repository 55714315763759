<div class="btn btn-danger   mt-2 mb-2">
    <button
      class=" btn  custom-btn btn-sm border-0 pr-35 font-12 w-120 text-start"
      (click)="openCloseFilter()"

    >
      MasterScriptFix
      
    </button>
    <ul
      class="filter-modal dropdown-menu show w-450 p-3 font-12 top-35"
      *ngIf="isFilterOpen"
      [formGroup]="searchForm"
    >
      <div class="row text-end">
        <div class="col-12">
          <button
            type="button"
            class="btn btn-sm font-12"
            (click)="close()"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-sm ms-2 font-12 fw-400"
            [disabled]="this.isApplyNotAvailble"
            (click)="apply()"
          >
            Apply
          </button>
        </div>
      </div>
  
      <div class="row mt-2">
  
        <div class="col">
            <ngx-skeleton-loader
            *ngIf="!isLeagueAvailable"
            [theme]="{ 'background-color': '#fcece8' }"
            count="1"
            appearance="line"
            animation="pulse"
          ></ngx-skeleton-loader>
          <mat-form-field *ngIf="isLeagueAvailable" appearance="outline" class="me-2">
            <mat-label>Leagues</mat-label>
            <mat-select
              placeholder="Leagues"
              formControlName="selectedLeague"
              
            >
              <mat-option *ngFor="let league of leagueList" [value]="league"
                >{{ league }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ngx-skeleton-loader
          *ngIf="!isMdlistAvailable"
          [theme]="{ 'background-color': '#fcece8' }"
          count="1"
          appearance="line"
          animation="pulse"
        ></ngx-skeleton-loader>
          <mat-form-field *ngIf="isMdlistAvailable" appearance="outline">
            <mat-label>MatchDay</mat-label>
            <mat-select
              placeholder="MatchDay"
              formControlName="matchDay"
              
            >
              <mat-option *ngFor="let team of madList" [value]="team.matchDay"
                >{{ team?.matchDay }} 
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="outline">
                <mat-label>Services</mat-label>
                <mat-select
                  placeholder="Services"
              formControlName="services"

                  
                >
                  <mat-option *ngFor="let team of services" [value]="team"
                    >{{ team }} 
                  </mat-option>
                </mat-select>
              </mat-form-field>
          </div>

      </div>
    </ul>
  </div>
  