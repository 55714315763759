import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path:'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path:'dashboard',canActivate:[AuthGuard],loadChildren:() => import('./dashboard/dashboard.module').then(m=>m.DashboardModule)
  },
  {
    path:'settings',canActivate:[AuthGuard],loadChildren:() => import('./settings/settings.module').then(m=>m.SettingsModule)
  },
  {
    path:'dff',canActivate:[AuthGuard],loadChildren:() => import('./dff/dff.module').then(m=>m.DffModule)
  },
  {
    path:'sff',canActivate:[AuthGuard],loadChildren:() => import('./sff/sff.module').then(m=>m.SffModule)
  },
  {
    path:'accounts',canActivate:[AuthGuard],loadChildren:() => import('./accounts/accounts.module').then(m=>m.AccountsModule)
  },
  {
    path:'users',canActivate:[AuthGuard],loadChildren:() => import('./users/users.module').then(m=>m.UsersModule)
  },
  {
    path:'assets',canActivate:[AuthGuard],loadChildren:() => import('./rft-assets/rft-assets.module').then(m=>m.RftAssetsModule)
  },
  {
    path:'notification',canActivate:[AuthGuard],loadChildren:() => import('./notification/notification.module').then(m=>m.RftNotificationModule)
  },
  {
    path:'subscription',canActivate:[AuthGuard],loadChildren:() => import('./subscription/subscription.module').then(m=>m.RftSubscriptionModule)
  },
  {
    path:'survey',canActivate:[AuthGuard],loadChildren:() => import('./survey/survey.module').then(m=>m.SurveyModule)

  },
  {
    path:'refer-earn',canActivate:[AuthGuard],loadChildren:() => import('./refer-earn/refer-earn.module').then(m=>m.ReferEarnModule)

  },
  // {
  //   path:'**',
  //   redirectTo:'settings/core',
  //   pathMatch:'full'
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
