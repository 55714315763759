<!-- <div class="row"> -->
<div id="sidebar-max" class="main-menubar" [class.open]="isFullSidebar">
  <!-- Logo -->
  <div class="mt-2 mb-2 d-flex">
    <img
      [ngClass]="isFullSidebar ? '' : ''"
      src="../../../assets/small-width-logo.png"
      alt=""
      style="height: 30px"
    />
    <img
      [ngClass]="isFullSidebar ? 'ps-2 transition' : 'ps-2 d-none transition'"
      src="../../../assets/full-width-logo.svg"
      alt=""
      style="height: 20px"
    />
  </div>

  <!-- First sidebar options -->
  <div class="upper-menu m-0">
    <!--  Sidebar opener button -->
    <div class="row m-0 p-0 ml-2">
      <ul class="burger-in-mobile">
        <img
          src="../../../assets/menu.svg"
          alt=""
          [ngClass]="!isFullSidebar ? '' : 'd-none'"
          role="button"
          style="height: 20px"
          (click)="burgerMenu()"
        />
        <img
          id="burger-menu-closed"
          src="../../../assets/cross.svg"
          alt=""
          [ngClass]="isFullSidebar ? '' : 'd-none'"
          role="button"
          style="height: 25px"
          (click)="burgerMenu()"
        />
      </ul>
    </div>

    <!-- Menu options -->
    <div class="m-0 p-0">
      <ul class="mb-0 sidebar-left-options">
        <ng-container *ngIf="isLoading">
          <li
            *ngFor="let item of [1, 2, 3, 4, 5, 6]"
            class="rounded-full skeleton-bg mx-auto"
            style="height: 41px; width: 40px"
          ></li>
        </ng-container>

        <li class="modified-tooltip" *ngFor="let menu of menuData | async">
          <a
            [routerLink]="menu.path"
            [routerLinkActive]="'active'"
            class="d-flex align-items-center sidebar-left-option"
            role="button"
          >
            <img
              class="inactive-menu-icon"
              [src]="menu.icon"
              alt=""
              style="height: 25px; padding-left: 3px"
            />
            <img
              class="active-menu-icon"
              [src]="menu.selectedIcon"
              alt=""
              style="height: 25px; padding-left: 3px"
            />
            <label
              id="mainMenuLabel"
              role="button"
              class="mainMenu-txt pl-20"
              [class.d-none]="!isFullSidebar"
              (click)="burgerMenu()"
              >{{ menu?.mainMenuLabel }}</label
            >
          </a>

          <div class="right">
            {{ menu?.mainMenuLabel }}
            <i></i>
          </div>
        </li>

        <!-- Production switcher button -->
        <li>
          <div class="px-2">
            <button
              type="button"
              class="btn btn-sm btn-outline-warning w-full"
              (click)="switchToProd()"
            >
              {{ envText }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <!-- Bottom options -->
  <div class="w-full">
    <button class="btn d-flex align-items-center" (click)="logOut()">
      <img
        role="button"
        src="../../../assets/logout.png"
        alt=""
        style="height: 25px"
      />
      <span *ngIf="isFullSidebar" class="mainMenu-txt pl-20"> Log Out </span>
    </button>

    <img src="../../../assets/buti-buti.svg" alt="" style="height: 50px" />
  </div>
</div>

<div class="sidebar col ps-0 pe-0">
  <div class="ps-3 mt-4">
    <p style="color: rgb(58, 68, 77)">
      <b>{{ mainMenuLabel }}</b>
    </p>
  </div>

  <ng-container id="submenu-section">
    <ul class="mb-0">
      <ng-container *ngIf="isLoading">
        <li
          *ngFor="let item of [1, 2, 3,4]"
          class="skeleton-bg"
          style="height: 53px; width: 100%; background: var(--light-selected)"
        ></li>
      </ng-container>

      <li *ngFor="let submenu of subMenus | async">
        <a
          [routerLink]="submenu.path"
          routerLinkActive="active"
          class="submenu mb-0 p-3 d-block"
          style="color: rgb(58, 68, 77)"
        >
          <img class="" [src]="submenu.icon" alt="" style="height: 20px" />
          <span class="ms-2">{{ submenu.submenuLabel }}</span>
        </a>
      </li>
    </ul>
  </ng-container>
</div>
<!-- </div> -->
