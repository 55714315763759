import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * @author subham
 * @purpose this class destroys any subscription when subscribed by piping takeUntil operator
 * @example
 * class MyComponent extends Unsubscriber{
 *  constructor(){
 *    super(); // calling extended class's constructor
 *
 *    $observable.pipe(
 *      takeUntil(this.$destroyRef)
 *    ).subscribed((res)=>console.log(res))
 *  }
 *
 * // You rest of the code ...
 * }
 */
@Injectable()
export class Unsubscriber implements OnDestroy {
  $destroyRef = new Subject();

  ngOnDestroy(): void {
    this.$destroyRef.next(true);
    this.$destroyRef.unsubscribe();
  }
}
